import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Search, Globe, ChevronDown } from "lucide-react";
import NotificationBell from "../NotificationBell";
import { Settings } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Balance from "./Balance";
import { AddressDisplay } from "./AddressDisplay";
import { useSIWEAuth } from "../../Services/useSIWEAuth";
import Breadcrumb from "./Breadcrumb";
import { useAppKitAccount } from "@reown/appkit/react";

const SettingsAppHeaderIcon = () => {
    /**
     * Navigation Settings Icon
     * Goes to Settings Page inside UserProfile.jsx
     */
    const navigate = useNavigate();
    const onClick = () => {
        navigate("/profile/settings");
        window.scrollTo(0, 0);
    };
    return <Settings className="h-5 w-5 text-zinc-300" onClick={onClick} />;
};
const AppHeader = () => {
    useSIWEAuth(); // Add this line
    const [searchQuery, setSearchQuery] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { address: connectedAddress, isConnected } = useAppKitAccount();
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // Navigation menus
    const mainNavItems = [
        {
            title: "Auctions",
            items: [
                { name: "Upcoming", href: "/" },
                { name: "Live", href: "/auctions" },
                { name: "Past", href: "/past" },
            ],
        },
        {
            title: "Staking",
            items: [
                { name: "Dashboard", href: "/staking" },
                { name: "Analytics", href: "/analytics" },
                { name: "Documentation", href: "/docs" },
            ],
        },
        {
            title: "Profile",
            items: [
                { name: "Profile", href: "/profile" },
                { name: "Achievements", href: "/achievements" },
                { name: "Settings", href: "/settings" },
            ],
        },
    ];

    const handleLinkClick = () => {
        setIsDropdownOpen(false);
    };

    return (
        <header className="fixed top-0 left-0 right-0 z-30 bg-zinc-900/75 backdrop-blur-[12px] border-b border-zinc-800/10 rounded-b-2xl">
            <div className="max-w-7xl mx-auto px-2 sm:px-4">
                <div className="flex items-center justify-between h-20 sm:h-26">
                    {/* Logo and Primary Nav */}
                    <div className="flex items-center space-x-2 sm:space-x-8">
                        {/* Logo with Dropdown */}
                        <div className="group relative" ref={dropdownRef}>
                            <div
                                className="flex items-center space-x-1 cursor-pointer py-2"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            >
                                <img
                                    src="/logo512.png"
                                    alt="Logo"
                                    className="h-8 w-8 sm:h-11 sm:w-11"
                                />
                                <span className="text-amber-500 font-medium pl-1 text-sm sm:text-base">
                                    BIDCOIN
                                </span>
                            </div>
                            <Breadcrumb />

                            {/* Dropdown positioned better for mobile */}
                            <div
                                className={`${
                                    isDropdownOpen ? "block" : "hidden"
                                } absolute top-[calc(100%+0.25rem)] left-0 w-40 sm:w-48 py-2 bg-zinc-900/90 backdrop-blur-xl rounded-xl shadow-xl border border-zinc-700/70`}
                            >
                                <Link
                                    to="/"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Home
                                </Link>
                                <Link
                                    to="/auctions"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Auctions
                                </Link>
                                <Link
                                    to="/profile"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Profile
                                </Link>
                                <Link
                                    to="/staking"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Staking
                                </Link>
                                <Link
                                    to="/how-bidcoin-auctions-work"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    How It Works
                                </Link>
                                <a
                                    href="https://whitepaper.bidcoin.live"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Whitepaper
                                </a>
                                {/* <Link
                                    to="/term-and-conditions"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Contact Us
                                </Link> */}
                                <Link
                                    to="/presale"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Presale
                                </Link>
                                <Link
                                    to="/airdrop"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Airdrop
                                </Link>
                                {/* <Link
                                    to="/referral"
                                    className="block px-4 py-2 text-sm text-zinc-300 hover:bg-zinc-700"
                                    onClick={handleLinkClick}
                                >
                                    Referral Program
                                </Link> */}
                            </div>
                        </div>
                    </div>

                    {/* Search Bar and Actions */}
                    <div className="flex items-center space-x-2 sm:space-x-4">
                        {/* Connect Button */}

                        {/* Icons */}
                        <div className="flex flex-col items-end">
                            <div className="flex items-center space-x-2 sm:space-x-4">
                                <div className="flex items-center space-x-2 sm:space-x-4 py-0">
                                    {/* Connect Button and Balance Container */}
                                    <div className="flex flex-col items-center gap-0">
                                        <button className="text-zinc-900 px-1 py-0.5 rounded-full text-sm font-medium transition-colors scale-90 sm:scale-100">
                                            {/* <w3m-button size="sm" balance="hide" /> */}
                                        </button>
                                        <Balance />
                                    </div>
                                </div>
                                {isConnected && <SettingsAppHeaderIcon />}
                                <NotificationBell />
                            </div>
                            <div className="flex justify-center">
                                <AddressDisplay />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default AppHeader;
